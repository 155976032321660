.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.innerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 80%;
}

.titleWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 128px 48px 16px 48px;
}

.titleText {
    color: #062762;
    font-size: 24px;
    font-weight: 700;
}

.contentBlockWrapper {
    padding: 48px;

    width: 100%;
    color: black;
    background: #F8F8F8;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.contentBlockTitle {
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: #062762;
    font-size: 20px;
    font-weight: 700;
}

.filtersExportWrapper {
    padding: 0 16px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filtersWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.closureDateTime {
    display: none;
}

.filterWrapper {
    padding: 8px 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #ffffff;
    border: 1px solid #E1E1E1;
    border-radius: 5px;
    text-align: center;

    color: #000000;
    font-weight: 700;

}

.filterWrapperSelected {
    border: 1px solid #E31240;
}

.filterOuterWrapper {
    position: relative;
}

.filterDateRange {
    position: absolute;
    bottom: -24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.exportWrapper {
    padding: 20px 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    background: #E31240;
    border-radius: 40px;

    color: #ffffff;
}

.tableWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 24px;

    width: 100%;
    border: 2px solid #B9B9B9;
    border-radius: 10px;
    background: #ffffff;
}

.tableTitleWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #79747e;
}

.tableTitle {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.tableTotal {
    font-size: 16px;
    font-weight: 700;
    color: #062762;
}

.tableGridRow {
    width: 100%;
    padding: 8px 20px;
    display: grid;
    grid-template-columns: 4fr 1fr 1fr;
    border-top: 1px solid #E1E1E1;
}

.tableGridRowFinal {
    background: #F5F5F5;
    border-top: 1px solid #79747e;
    padding: 20px;
}

.tableGridRowFinal > .gridItemTitleWrapper > .gridItemTitle {
    color: #062762;
    font-weight: 700;
}

.tableGridRowFinal > .gridItemTotal {
    color: #062762;
    font-weight: 700;
}

.gridItemTitleWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.gridItemTitle {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
}

.gridItemSubtitle {
    font-size: 16px;
    font-weight: 300;
    color: #79747e;
}

.gridItemQuantity {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
}

.gridItemTotal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
}

.gridItemHeading {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
}

.printHeadingWrapper {
    display: none;
}

/*toto presunut to @media print*/
@media (max-width: 1300px){
    .container {
        height: 90vh;
    }

    .innerWrapper {
        width: 95%;
    }

    .titleWrapper {
        display: none;
    }

    .contentBlockTitle {
        display: none;
    }

    .filtersWrapper {
        display: none;
    }

    .exportWrapper {
        display: none;
    }

    .tableGridRow {
        grid-template-columns: 46% 27% 27%;
    }

    .container *{
        font-size: 12px;
    }

    .contentBlockWrapper {
        padding: 16px;
    }

    .tableWrapper {
        padding: 8px;
    }

    .tableGridRowFinal {
        padding: 10px 20px;
    }

    .printHeadingWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

    .printHeadingContentWrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .printHeadingLeft {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .printHeadingRight {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
    }
}

@media print {
    .container {
        height: 100%;
    }
}
.pen {
    color: #E6264C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-left: 10px;
}

.receipt {}

@media (min-width: 801px) {
    .container {
        width: 1360px;
        background-color: #F8F8F8;
        border-radius: 10px;
        padding: 38px 100px 36px 20px;
    }

    .buttonNext {
        width: 300px;
        height: 50px;
        padding: 13px 16px 13px 24px;
        margin-top: 30px;
        margin-left: 82%;
    }


    .box {
        padding: 10px 10px 10px 12px !important;
        border: 1px solid #79747E;
        width: 408px;
        height: 40px;
        border-radius: 5px;
        background-color: #FFFFFF;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .mobile_only {
        display: none;
    }

    .title_row {
        display: flex;
        max-width: 1280px;
        justify-content: space-between;
        align-items: flex-start;
    }

    .titleRight {
        display: flex;
    }

    .sudTitleRight {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .sudTitleRow {
        display: flex;
        align-items: center;
    }

    .radio {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .attentionTitle {
        color: #000000;
        font-size: 16px;
        font-weight: bold;
        margin: 0 20px 0 0;
    }

    .freeTicketsBold {
        font-size: 16px;
        color: #000000;
        font-weight: 900;
    }

    .innerBox {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 30px;
        margin-right: 30px;
        padding-left: 20px;
    }

    .mainBox {
        width: 100%;
        max-width: 895px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #E1E1E1;
        border-radius: 10px;
        background-color: #FFFFFF;
    }

    .ticketsBox {
        width: 1300px;
        max-height: 312px;
        display: flex;
        align-items: flex-end;
        gap: 10px;
        border-radius: 10px;
        padding: 16px;
    }


    .notesBox {
        width: 430px;
        padding: 8px 0 30px 0;
        color: #79747E;
        margin-left: 20px;
    }

    .notes {
        font-size: 14px;
        color: #000000;
        font-weight: 600;
        padding: 0 5px 0 0;
    }

    .tour_name {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: Montserrat, serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #062762;
        margin: 16px 0 16px 35px;
    }

    .innerBox {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        row-gap: 5px;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 33%;
    }

    .subTitleBox {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .price {
        width: 25%;
        display: flex;
        justify-content: flex-end;
        margin-right: 30px;
        color: #000000;
        font-weight: 600;
    }

    .notesGrey {
        padding-top: 2px;
        color: #79747E;
        font-size: 14px;
        margin: 0 0 0 10px;
        font-weight: 400;
    }

    .ladelBox {
        padding: 17.5px 29px 21px 29px;
        width: 267px;
        height: 209.5px;
        background-color: #FFFFFF;
        border-radius: 10px;
        border: 1px solid #79747E;
    }

    .line {
        width: 267px !important;
        margin: 10px 0px !important;
        height: 1px;
        border-top: 0.5px dotted #757575;
    }

    .boxRules {
        padding: 20px 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .text {
        font-size: 16px;
    }

    .dot {
        color: #E31340;
    }

    .rules {
        padding: 20px 0 0 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .buttonToGo {
        position: relative;
        width: 212px;
        height: 50px;
        padding: 6px 12px;
        background-color: #E31340;
        color: white;
        text-decoration: none;
        display: flex;
        border-radius: 25px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .desktop_label {
        display: block;
    }

    .logo {
        width: 109.34px;
        height: 44px;
    }

    .rowLabel {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .rowLabelText {
        font-size: 16px;
    }

    .priceBlock {
        margin-top: 90px;
    }

    .closedBox {
        width: 1255.2px;
        height: 84px;
        color: #B9B9B9;
        background-color: #F8F8F8;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 54px 0px 50.8px;
    }

    .rowBox {
        display: flex;
        align-items: center;
    }
}

@media (min-width: 0px) and (max-width: 420px) {
    .closedBox {
        margin: auto;
        width: 100vw;
        height: 18px;
        color: #B9B9B9;
        background-color: #F8F8F8;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 21px 22px 21px 25.8px;
    }

    .box {
        border-radius: 10px;
        /*width: 295px !important;*/
        height: 60px;
        flex-shrink: 0;
        background-color: #F8F8F8;
        padding: 0px !important;
        margin: auto;
        margin-left: -5px;
    }

    .container {
        height: 1198px;
        width: 100%;
        flex-shrink: 0;
        background-color: #F8F8F8;
        border-radius: 10px;
        padding: 19px 17px 35px 15px !important;
        margin: auto;
    }

    .ladelBox {
        padding: 18.32px 29.5px 16.25px 28.5px;
        width: 92%;
        height: 155.43px;
        background-color: #FFFFFF;
        border-radius: 10px;
        border: 1px solid #79747E;
    }

    .line {
        width: 245px !important;
        margin: 10px 0px !important;
        height: 1px;
        border-top: 0.5px dotted #757575;
    }
}

@media (max-width: 800px) {
    .container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: auto;
        width: 100%;
        flex-shrink: 0;
        background-color: #F8F8F8;
        border-radius: 10px;
        padding: 19px 17px 35px 15px !important;
        margin: 10px auto 0 auto;
    }

    .tour_name {
        font-family: Montserrat, serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #062762;
    }

    .title_row {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .desktop_only {
        display: none;
    }

    .desktop_label {
        display: block;
    }

    .radio {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .attentionTitle {
        color: #000000;
        font-size: 18px;
        font-weight: 700;
        padding: 15px 0 15px 0;
    }

    .notesBox {
        width: 100%;
        padding: 15px 5px;
        color: #79747E;
    }

    .buttonNext {
        margin-top: 40px;
    }


    .notes {
        font-size: 14px;
        color: #79747E;
        padding: 0 5px 0 0;
        font-weight: 600;
    }

    .freeTicketsBold {
        font-size: 16px;
        color: #000000;
        font-weight: 900;
    }

    .mainBox {
        display: grid;
        width: 100%;
        color: #000000;
        grid-template-rows: 1fr;
        row-gap: 20px;
        border: 1px solid #E1E1E1;
        border-radius: 10px;
        padding: 16px;
        background-color: #FFFFFF;
    }

    .ticketsBox {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 10px;
    }

    .innerBox {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 5px;
    }

    .box {
        padding: 10px 10px 10px 12px !important;
        border: 1px solid #79747E;
        width: 100%;
        height: 60px;
        border-radius: 5px;
        background-color: #FFFFFF;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 42%;
    }

    .subTitleBox {
        width: 36%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .price {
        width: 22%;
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
        color: #000000;
        font-weight: 600;
    }

    .notesGreyBox {
        margin: 5px 20px 0 10px;
    }

    .notesGrey {
        color: #79747E;
        font-size: 14px;
        font-weight: 400;
    }

    .ladelBox {
        padding: 18.32px 29.5px 16.25px 28.5px;
        width: 92%;
        height: 175px;
        background-color: #FFFFFF;
        border-radius: 10px;
        border: 1px solid #79747E;
    }

    .boxRules {
        padding: 15px 20px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .line {
        width: 285px;
        margin: 10px 0px;
        height: 1px;
        border-top: 0.5px dotted #757575;
    }

    .text {
        font-size: 16px;
    }

    .dot {
        color: #E31340;
    }

    .rules {
        padding: 20px 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .rule {
        margin: 10px 0 0 0;
    }

    .logo {
        width: 84.31px;
        height: 44px;
    }

    .rowLabel {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .rowLabelText {
        font-size: 16px;
    }

    .priceBlock {
        margin-top: 40px;
    }

    .closedBox {
        margin: auto;
        width: 96vw;
        height: 18px;
        color: #B9B9B9;
        background-color: #F8F8F8;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 22px 30px 5px;
    }

    .rowBox {
        display: flex;
        align-items: center;
    }


    .buttonToGo {
        position: relative;
        width: 212px;
        height: 50px;
        padding: 6px 12px;
        background-color: #E31340;
        color: white;
        text-decoration: none;
        display: flex;
        border-radius: 25px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
    }
}

@media (min-width: 1023px) {
    .box {
        height: 70px;
        margin: 0;
    }

    .title_row {
        flex-direction: column;
    }

    .innerBox {
        grid-template-rows: auto;
    }

    .receipt {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 20px;
    }
}
.wrapper {
    position: fixed;
    top: 85%;
    left: 4%;
    width: 93%;
    height: 74px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: 1px solid transparent;
    border-radius: 60px;
    background: #E31240;
    z-index: 11;
}

.sub_wrapper_left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.sub_wrapper_left_counter {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.sub_wrapper_right {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sub_wrapper_left_sub_content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.text {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-align: left;
}
*, *::before, *::after {
  box-sizing: border-box;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 32px;

    padding: 54px 54px 36px 54px;
    margin-top: 100px;
}

.title{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: 24px;
    font-weight: 700;
    color: #062762;
}

.panelWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.panelTab {
    height: 32px;
    min-width: fit-content;
    padding: 8px 16px;

    color: #B9B9B9;
    border: 2px solid #B9B9B9;
    border-radius: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    position: relative;
}

.panelTabActive {
    border: 2px solid #062762;
    color: #062762;
}

.panelTabDone {
    border: 1px solid #062762;
    color: #062762;
}

@media (max-width: 800px) {
    .panelTab {
        padding: 4px 8px;
    }
}
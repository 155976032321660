.mobile_only {
display: none; 
}
.transparent{
margin-right: 1vw;
}
.primary{
margin-right: 1vw;
}
.secondary{
margin-right: 1vw;
}
.contact{
margin-right: 1vw;
}
.social{
margin-right: 8px;
margin-left: 1vw;
flex-shrink: 0;    
}
.logoWrapper {
top: 90px;
left: 320px;
margin: 10px 1.5vw 10px 0;
display: flex;
}
.logo {
width: 199px;
height: 80px;
}

@media (max-width: 1280px) {
.transparent{
margin-right: 1vw;
white-space: noWrap;
}
.primary{
margin-right: 1vw;
white-space: noWrap;
}
.secondary{
margin-right: 1vw;
white-space: noWrap;   
}
.contact{
margin-right: 0px;
white-space: noWrap;   
}
.social{
margin-right: 0px;
margin-left: 0px;
flex-shrink: 0;    
}}

.container {
    z-index: 100;
}

@media (max-width: 768px) {
.mobile_only {
display: block; 
}
.desktop_only {
display: none; 
}
.hamburger {
cursor: pointer;
}}

@media print {
    .container {
        display: none;
    }
}

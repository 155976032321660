.box {
    width: 100%;
    margin: auto;
    height: 240px;
    position: relative;
    max-width: 100vw;
}

.imageContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: auto;
}

.image {
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlayPic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #062762E3;
    background-repeat: no-repeat;
    z-index: 1;
    overflow: hidden;
}

.overlay {
    position: absolute;
    width: 1360px;
    height: 240px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    z-index: 2;
}

.boxHeader {
    position: relative;
    width: 1360px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    margin-bottom: -50px;
}

.boxBody {
    display: flex;
    flex-direction: column;
    width: 1360px;
}

.gridItem {
    width: 100%;
    max-width: 1360px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 30px;
}

.horizontal_line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #f8f8f8;
    margin-bottom: -40px;
}

.logo_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}

.logo_text {
    font-family: Montserrat, serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 11.72px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}


.logo {
    width: 153.3px;
    height: auto;
    max-width: 154px;
    max-height: 80px;
}

.phoneEmail {
    display: flex;
    justify-content: space-between;
}

.icons {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 24px;
    white-space: nowrap;
}

@media (max-width: 1640px) {
    .box {
        margin: auto;
        height: 240px;
        position: relative;
        max-width: 1640px;
    }

    .overlay {
        width: 1200px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        z-index: 2;
    }

}

@media (max-width: 1280px) {
    .box {
        margin: auto;
        height: 240px;
        position: relative;
        max-width: 1280px;
    }

    .imageContainer {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .overlay {
        width: 825px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        z-index: 2;
    }

}

@media (max-width: 860px) {
    .socialIconsMobile {
        display: block;
        padding: 0 0 1vh 10px;
    }

    .phoneEmail {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
        padding: 0 0 1vh 10px;
        margin-top: -35px;
    }

    .icons {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 10px;
    }

    .socialIconsDesktop {
        display: none;
    }

    .box {
        margin: auto;
        height: 240px;
        position: relative;
        max-width: 860px;
    }

    .imageContainer {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .overlay {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        z-index: 2;
    }

    .boxHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 2;
    }

    .boxHeaderLogo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .logo {
        width: 149.1px;
        height: 60px;
        margin: 1vh 0;
    }

    .boxBody {
        display: flex;
        flex-direction: column;
        width: 560px;
    }

    .gridItem {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
    }
}



.container {
    margin: auto;
    background-color: #F8F8F8;
    max-width: 100vw;
    height: 90vh;
    padding: 130px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    max-width: 100vw;
    padding: 0 60vw 0 0;
}

.disabled_button {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 15px 0 !important;
    width: 500px;
    height: 100px;
    background: #062762;
    border-radius: 5px;
    cursor: not-allowed;
 opacity: 0.7;
}

.button {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 15px 0 !important;
    width: 500px;
    height: 100px;
    background: #062762;
    border-radius: 5px;
    cursor: pointer;
}

.caret {
    width: 60px;
}

.icon {
    width: 32px;
    margin: 0 5px 0 45px;
}

.buttonContent {
    display: flex;
    flex-direction: column;
}

.buttonText {
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    margin-left: 20px;
    cursor: pointer;
}

.disabled_button_text {
 text-align: left;
 font-size: 24px;
 font-weight: bold;
 margin-left: 20px;
 cursor: pointer;

}

.footer {
    margin: 40px 0;
    width: 642px;
    text-align: center;
    color: #000000;
    font-size: 18px;
    font-weight: 400;
}




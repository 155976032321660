.logoWrapper {
top: 90px;
left: 320px;
margin: 10px 1.5vw 10px 0;
display: flex;
cursor: pointer;
}
.logo {
width: 199px;
height: 80px;
}


*, *::before, *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.container {
    height: 100vh;
    width: 100vw;
    background: rgba(6, 39, 98, 1) url("../../../assets/5821fe42b9e958160511b2284b35b06d.jpg") no-repeat center;
    background-blend-mode: multiply;
    background-size: cover;
    overflow: hidden;
}


.contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 24px;
    height: 100%;
    width: 100%;
}

.logoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 300px;
}

.formWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
}

.formTitle {
    font-size: 36px;
    font-weight: 600;
}

.submitButton {
    background-color: #E31340;
    color: #ffffff;
    border-radius: 40px;
    padding: 16px 44px;
    transition: ease-in-out 0.5s;
}

.submitButton:hover {
    cursor: pointer;
    background-color: #bd1036;
}

.footerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
}


.container {
    display: flex;
    height: min-content;
    padding-left: 5px;
    gap: 25px;

    @media (max-width: 1400px) {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1400px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 799px) {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
    }
}

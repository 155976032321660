.container {
margin: auto;
background-color: #F8F8F8;
max-width: 1920px;
height: auto;
padding: 20px 0;
display: flex;
flex-direction: column;
align-items: center;
}

@media (max-width: 800px) {
.container {
margin: auto;
background-color: #F8F8F8;
width: 412px;
height: 506px;
display: flex;
flex-direction: column;
align-items: center;
}

.header{
margin: -120px 0 0 0;
}}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('src/assets/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('src/assets/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('src/assets/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('src/assets/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('src/assets/Montserrat-Bold.ttf') format('truetype');
}

body {
  font-family: "Montserrat", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #fff;
  max-width: "1920px";
}

h1, h2, h3, p {
  cursor: default;
}

input:-webkit-autofill {
  caret-color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    box-shadow: 0 0 0 30px transparent inset !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #FFFFFF !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
}

.css-1vvkud8-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
    -webkit-animation-duration: 5000s;
    animation-duration: 5000s;
    -webkit-animation-name: mui-auto-fill;
    animation-name: mui-auto-fill;
}

.component {
    margin: auto;
    margin-top: 15px;
    position: relative;
}
/*.box {*/
/*margin: auto;*/
/*position: relative;*/
/*width: 1360px;*/
/*padding: 0px 0 50px 0;*/
/*}*/

.box {
    max-width: 1360px;
    width: 100%;
    margin: auto;
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.box > div {
    width: 100%;
}
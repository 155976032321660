.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 5px;
    cursor: pointer;
    flex-wrap: wrap;
}


@media (min-width: 1023px) {
    .wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 5px;
        gap: 15px;
    }
}
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.timer {
    width: 100%;
    padding: 0px;
    margin: 0 0 0 5px;
    font-size: 32px;
    text-align: left;
}